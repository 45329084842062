<template>
<div>
    <Processing v-if="isLoading" />
    <div v-else class="lesson-view">
        <form @submit.prevent="editDailySuperFoodMessage">
            
            <div class="form-group">
                <label for="">Title</label>
                <input type="text" class="form-control" required v-model="lesson.title">
            </div>

            <div class="form-group">
                <label for="">Reference Text</label>
                <input type="text" class="form-control" required v-model="lesson.reference">
            </div>
            
            <div class="form-group">
                <label for="">Content</label>
                <TextareaAutosize
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.content"
                    :min-height="30"
                    :max-height="350" />
            </div>

            <div class="form-group">
                <label for="">Prayer and Confession</label>
                <TextareaAutosize
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.action"
                    :min-height="30"
                    :max-height="350" />
            </div>


            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isThought">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">Thoughts</label>
                </div>
                <TextareaAutosize
                    v-if="lesson.isThought"
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.thought"
                    :min-height="30"
                    :max-height="350" />
            </div>
            
            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isSoWhat">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">So What?</label>
                </div>
                <TextareaAutosize
                    v-if="lesson.isSoWhat"
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.sowhat"
                    :min-height="30"
                    :max-height="350" />
            </div>
            
            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isQuestion">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">Question</label>
                </div>
                <TextareaAutosize
                    v-if="lesson.isQuestion"
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.question"
                    :min-height="30"
                    :max-height="350" />
            </div>

            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isLessonLearned">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">Lesson Learned</label>
                </div>
                <TextareaAutosize
                    v-if="lesson.isLessonLearned"
                    required
                    class="form-control"
                    placeholder=""
                    ref="myTextarea"
                    v-model="lesson.lessonlearned"
                    :min-height="30"
                    :max-height="350" />
            </div>

            <div class="form-group">
                <div class="variables">
                    <div class="toggle-switch">
                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isPhoto">
                        <i class="toggle-switch__helper"></i>
                    </div>
                    <label for="">Photo</label>
                </div>

                <div v-if="lesson.isPhoto">
                    <input type="file" class="lesson-photo" ref="photo" accept="image/*">
                    <button class="btn btn-warning m-b-20" type="button" @click="selectPhoto">Select Photo</button>

                    <croppa v-model="myCroppa"
                        ref="myCroppa"
                        accept="image/*"
                        placeholder="Preview Image" 
                        :width="400" 
                        :height="300"
                        :quality="1"
                        :prevent-white-space="true"
                        :show-remove-button="true"
                        :initial-image="lesson.isPhoto ? lesson.photo : '' "
                        />
                        
                </div>


            </div>
            
            <button class="btn btn-success" :disabled="isProcessing">
                <span>Edit Lesson</span>
                <loader v-if="isProcessing" />
            </button>

        </form>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            // year: this.$store.state.devotionalYear,
            lesson: {
                title: "",
                reference: "",
                content: "",
                action: "",
                isThought: false,
                isPrayer: false,
                isSoWhat: false,
                isQuestion: false,
                isLessonLearned: false,
                isPhoto: false,
                thought: "",
                question: "",
                prayer: "",
                sowhat: "",
                lessonlearned: "",
                photo: "",
            },
            isProcessing: false,
            isLoading: false,
            myCroppa: {},
        }
    },
    created(){
        this.getLesson()
    },
    methods: {

        async getLesson(){
            this.isLoading = true
            const month = this.$route.params.month
            const lessonId = this.$route.params.lessonId
            const year = this.$store.state.devotionalYear
            const doc = await this.$db
                .collection("devotionals")
                .doc("dailysuperfood")
                .collection("year")
                .doc(year)
                .collection(month)
                .doc(lessonId)
                .get()
            
            this.lesson = doc.data()
            this.isLoading = false
        },

        async editDailySuperFoodMessage(){
            this.isProcessing = true
            if (this.lesson.isPhoto === true) {
                const photoData = this.$refs.myCroppa.generateDataUrl()
                this.lesson.photo = photoData != "" ? photoData : ""
            } else {
                this.lesson.isPhoto = false
                this.lesson.photo = ""
            }
            const editDailySuperFoodMessage = this.$fn.httpsCallable("editDailySuperFoodMessage")
            await editDailySuperFoodMessage(this.lesson)
            this.$toast.success("Lesson editted successfully")
            this.isProcessing = false
        },

        selectPhoto(){
            this.$refs.myCroppa.chooseFile()
        },

    },
    components:{
        "Processing": () => import("@/components/Loaders/Processing.vue"),
        "loader": () => import("@/components/Loaders/Button.vue"),
    }
}
</script>
